import { Controller } from "stimulus"

export default class extends Controller { 
  static targets = ['navigation', 'dropdown']

  // connect() {}
  get searchController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('.search-switch'), "search");
  }

  closeAllDropdowns() {
    const allDropdowns = this.dropdownTargets
    allDropdowns.forEach(function(el) { el.classList.remove('active') })
  }

  toggleNavigation(e) {
    e.preventDefault();
    
    e.target.classList.toggle('active')
    this.navigationTarget.classList.toggle('active')
  }

  toggleDropdown(e) {
    e.preventDefault();
    const index = e.target.dataset.index
    const allDropdowns = this.dropdownTargets
    const currentDropdown = allDropdowns.filter(function(val) { return val.dataset.index == index })[0]
    const restOfDropdowns = allDropdowns.filter(function(val) { return val.dataset.index != index })

    // const searchDropdown = document.querySelector(".search-switch__label.active")
    // searchDropdown ? searchDropdown.classList.remove("active") : ''

    this.searchController.closeDropdown();

    restOfDropdowns.forEach(function(el) { el.classList.remove('active') })
    currentDropdown.classList.toggle('active')
  }
}
