// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// Polyfills
import "core-js/stable";
import 'whatwg-fetch'
import 'element-closest-polyfill'
import 'focus-visible/dist/focus-visible.min.js'
import CustomEvent from 'custom-event-polyfill';
import objectFitImages from 'object-fit-images';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo

const application = Application.start()
import "regenerator-runtime/runtime";
const context = require.context("./controllers", true, /.js$/)
application.load(
  definitionsFromContext(context)
)

import * as u from 'JS/utils.js'
u.domReady(() => {
  objectFitImages('img', {watchMQ: true});
});



  import '/tobar/app/components/search_module/search_component.js';

  import '/tobar/app/components/video/video_component.js';

  import '/tobar/app/components/cookies_bar/cookies-bar.js';

