import * as u from 'JS/utils.js'

u.domReady(() => {
  const advancedSearchToggle = document.querySelectorAll("[js-search-toggle-selector]");
  const advancedSearchFilter = document.querySelectorAll("[js-search-filter-selector]");

  Array.from(advancedSearchToggle).forEach(function(button) {
    button.addEventListener("click", function(e) {
      e.preventDefault();
      button.classList.toggle('active');
      button.parentElement.parentElement.querySelector("[js-search-filter-selector]").classList.toggle('active');
    })
  });
});
