import WaveSurfer from 'wavesurfer.js';
import { Controller } from 'stimulus';
let throttle = require('lodash/throttle');

export default class extends Controller {
  static targets = ['audio', 'waveOutput'];

  connect() {
    this.player = this.initPlayer(this.audioTarget);
    this.player.on('ready', () => {
      this.buildUI(this.element);
    });
  }

  disconnect() {
    this.player.destroy()
  }

  initPlayer(element) {
    const src = element.dataset.src;

    const waveplayer = WaveSurfer.create({
      container: this.waveOutputTarget,
      barWidth: 1,
      barGap: 1,
      waveColor: '#646363',
      progressColor: '#0280a5',
      backend: 'MediaElement',
    });

    waveplayer.load(src);
    element.style.display = 'none'; // hide audio element once we're done with it.
    return waveplayer;
  }

  setPlaybackSpeed(e) {
    const formData = new FormData(e.currentTarget);
    const formProps = Object.fromEntries(formData);
    this.player.setPlaybackRate(formProps['speed'])
  }

  buildUI(element) {
    const playBtn = element.querySelector('.play-btn');
    const stopBtn = element.querySelector('.stop-btn');
    const muteBtn = element.querySelector('.mute-btn');
    const volumeSlider = element.querySelector('.volume-slider');
    const progressCurrent = element.querySelector('.progress-current');
    const progressTotal = element.querySelector('.progress-total');

    progressTotal.innerText = this.secondsToTimestamp(
      this.player.getDuration(),
    );

    progressCurrent.innerText = this.secondsToTimestamp(
      this.player.getCurrentTime(),
    );

    const updateProgressCurrent = () => {
      progressCurrent.innerText = this.secondsToTimestamp(
        this.player.getCurrentTime(),
      );
    };

    this.player.on('audioprocess', throttle(updateProgressCurrent, 250));

    playBtn.addEventListener('click', () => {
      this.player.playPause();

      if (this.player.isPlaying()) {
        playBtn.classList.add('playing');
      } else {
        playBtn.classList.remove('playing');
      }
    });

    stopBtn.addEventListener('click', () => {
      this.player.stop();
      playBtn.classList.remove('playing');
    });

    volumeSlider.addEventListener('mouseup', () => {
      changeVolume(volumeSlider.value);
    });

    const changeVolume = (volume) => {
      if (volume == 0) {
        muteBtn.classList.add('muted');
      } else {
        muteBtn.classList.remove('muted');
      }

      this.player.setVolume(volume);
    };

    muteBtn.addEventListener('click', () => {
      if (muteBtn.classList.contains('muted')) {
        muteBtn.classList.remove('muted');
        this.player.setVolume(0.5);
        volumeSlider.value = 0.5;
      } else {
        this.player.setVolume(0);
        muteBtn.classList.add('muted');
        volumeSlider.value = 0;
      }
    });
  }

  secondsToTimestamp(raw_seconds) {
    const dateObj = new Date(raw_seconds * 1000);
    const paddedValue = (time) => {
      return `${time.toString().padStart(2, '0')}`;
    };
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();
    const optionalHours = hours != 0 ? `${paddedValue(hours)}:` : '';
    return (
      optionalHours + paddedValue(minutes) + ':' + paddedValue(seconds)
    );
  }
}
