import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleable'];
  toggle() {
    const css_class = 'visually-hidden';
    if (this.toggleableTarget.classList.contains(css_class)) {
      this.toggleableTarget.classList.toggle(css_class);
    } else {
      this.toggleableTarget.classList.add(css_class);
    }
  }

  exit(e) {
    const css_class = 'visually-hidden';
    if(this.element === e.target || this.element.contains(e.target)) return;

    this.toggleableTarget.classList.add(css_class)
  }
}
