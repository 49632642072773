import { Controller } from "stimulus"

export default class extends Controller {
  static targets =['main']

  get navigationController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('.header-navigation'), "navigation");
  }

  closeDropdown() {
    this.mainTarget.classList.remove('active');
  }

  clickOutsideExit(e) {
    if (this.element === e.target || this.element.contains(e.target)) return;

    this.mainTarget.classList.remove('active');
  }


  toggleDropdown(e) {
    e.preventDefault();

    this.navigationController.closeAllDropdowns();

    this.mainTarget.classList.toggle('active');

    const searchInput = document.querySelector('input[type=search]');

    if (searchInput != null) {
      searchInput.focus();
    }
  }
}
