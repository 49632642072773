import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['typeSelect', 'field'];
  connect() {
    if (this.hasTypeSelectTarget) {
      this.toggleOptions();
    }
  }

  toggleOptions(e) {
    const currentOption = this.typeSelectTarget.value;

    if (currentOption == null) {
      return;
    }

    this.fieldTargets.forEach((field) => {
      if (this.fieldEnabled(field, currentOption)) {
        field.style.display = 'flex';
      } else {
        const select = field.querySelector('select');
        select.selectedIndex = 0;
        field.style.display = 'none';
      }
    });
  }

  fieldEnabled(field, option) {
    if (field.dataset.enabledOn === undefined) {
      return true;
    }

    if (option == 'all') {
      return true;
    }

    return field.dataset.enabledOn.split(',').includes(option);
  }
}
