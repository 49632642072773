import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    max: Number,
  }

  static targets = ['remainder']

  connect() {
    length = this.element.innerText.length
    if (length > this.maxValue) {
      this._initializeTrunactor()
    }


  }

  show(e) {
    e.preventDefault();

    if (e.currentTarget.innerText == 'Read less...') {
      e.currentTarget.innerText = '...Read more';
      this.remainderTarget.style.display = 'none';
    } else {
      e.currentTarget.innerText = 'Read less...';
      this.remainderTarget.style.display = 'inline';
    }
  }

  _initializeTrunactor() {
    const initialText = this.element.innerHTML.slice(0, this.maxValue);
    const remainingText = this.element.innerHTML.slice(this.maxValue);
    const output = `${
      this._renderInitialText(initialText)
    }${
      this._renderRemainingText(remainingText)
    }${
      this._renderToggleLink(remainingText.length)
    }`;

    this.element.innerHTML = output;
  }


  _renderToggleLink(count) {
    return `<a href="#" style="display: block;" class="block margin-top--small link" data-action="truncated-text#show">...Read more</a>`;
  }

  _renderInitialText(text) {
    return `<span>${text}</span>`;
  }

  _renderRemainingText(text) {
    return `<span data-truncated-text-target="remainder" style="display: none;">${text}</span>`;
  }
}
