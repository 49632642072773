import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['switch', 'input']
  static values = { lang: String }

  initialize() {
    this.url = this.switchTarget.dataset.path
    this.isQuery = this.url.includes('?');
    this.query = this.url.split('?').pop();
    this.multipleQueries = this.query.includes('&');
    this.isLangQuery = this.query.includes('l=');
    this.queryArr = this.query.split('&');
    this.langQuery = this.queryArr.filter(function(el) {
      return el.includes('l=')
    })[0]

    this.language = this.langValue
  }

  connect() {
    if (this.language == 'gd') {
      this.inputTarget.checked = true
    }
  }

  languageSwitch() {
    const url = new URL(window.location);
    let searchParams = new URLSearchParams(url.search);
    let newUrl = window.location.pathname
    if (this.language == 'gd') {
			searchParams.set('l', 'en')
      newUrl = [newUrl, searchParams.toString()].join("?")
    } else {
			searchParams.set('l', 'gd')
      newUrl = [newUrl, searchParams.toString()].join("?")
    }
    window.location.href = newUrl
  }
}
